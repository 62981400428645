//
// Storytelling
// --------------------------------------------------

@storytellingTrackWidth: .625em;
@storytellingTrackBorderRadius: @border-radius-base;
@storytellingIconSize: 3.25em;

.c-storytelling {
  position: relative;
}

// Track
.c-storytelling__track {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2.5em;
  width: @storytellingTrackWidth;
  z-index: @zindex-navbar - 100; // higher than content, lower than bootstraps absolute components

  // hide initially
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: all @anim-std;

  background-color: @color-gray-d;
  border-radius: @storytellingTrackBorderRadius;

  @media @tablet {
    left: calc(~"((100% -" @containerWidthSlim ~") / 2) + 3.25em");
  }

  @media @desktop {
    left: calc(~"((100% -" @containerWidthSlim + @grid-gutter-width * 2 ~") / 2) - 3.25em");
  }

  @media @desktopLarge {
    left: calc(~"((100% -" @containerWidthSlim + @grid-gutter-width * 2 ~") / 2) - 6em");
  }

  // fade in track when JS thinks we're ready
  .c-storytelling.has-loaded & {
    opacity: 1;
    transform: translateY(-25vh);
  }

  // no top-margin for first page item
  & + .c-page__item {
    margin-top: 0;
  }
}

// Progress
.c-storytelling__progress {
  width: 100%;
  height: 0; // height will be set by JS
  transition: height 1s cubic-bezier(.3, 1.34, .58, 1);

  background-color: @color-content-inverted;
  border-radius: @storytellingTrackBorderRadius;

  display: flex;
  align-items: flex-end;
}

// Icons
.c-storytelling__icons {
  flex-shrink: 0;
  position: relative;
  left: 50%;

  // sizing through font-size (so box-shadow is responsive)
  font-size: 1rem;
  width: @storytellingIconSize;
  height: @storytellingIconSize;

  transition: transform @anim-std;
  transform: translate(-50%, 50%);

  @media @tablet {
    font-size: 1.38rem;
  }

  .has-open-waymarker & {
    transform: translateX(-50%) translateY(-2rem) translateY(-1em); // 2rem to invert padding, 1em for real padding
  }
}

.c-storytelling__icons-inner {
  display: block;
  height: 100%;
  width: 100%;

  // Pulse animation
  transform: translate3d(0, 0, 0);
  border-radius: 50%;
  box-shadow: 0 0 0 fade(@color-content-inverted, 70%);

  .is-nervous & {
    animation: pulse 1.25s infinite cubic-bezier(.66, 0, 0, 1);
  }
}

// Pulse animation
@keyframes pulse {
  50% {
    transform: scale(1.1);
  }

  100% {
    box-shadow: 0 0 0 2.8em transparent;
  }
}

.c-storytelling__icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: all @anim-std;

  &.is-active {
    opacity: 1;
  }
}

.c-storytelling__icons-text {
  .font-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  padding-left: 2.5em;

  text-transform: uppercase;
  white-space: nowrap;

  transform: translateY(-50%) rotate(-45deg);
  opacity: 0;
  transform-origin: left center;
  transition: all @anim-std;

  &.is-active {
    opacity: 1;
    transform: translateY(-50%);
  }
}

// Step
.c-storytelling__step-wrapper {
  position: relative;
}

.c-storytelling__step {
  height: 45vh; // icon will continue scrolling after this value
  position: absolute;
  top: 5em; // 'padding'
  left: 0;
  right: 0;
  z-index: -1;
}

.c-storytelling__step--waymarker {
  top: @waymarker-initial-height / 2; // so the icon will be vertically centered
  bottom: 0;
  height: auto;
  min-height: 45vh;
}
