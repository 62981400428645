.c-quote {
  padding: 0;
  border: 0 none;
  font-size: inherit;

  max-width: 30em;
  margin-left: auto;
  margin-right: auto;

  .font-smoothing;
  .font-serif;
  color: @color-content-inverted;
  font-style: italic;

  @media @desktop {
    max-width: none;
  }
}

.c-quote__quote {
  font-size: @fs-3md;
  line-height: @lh-sm;
  margin-bottom: 0;
  position: relative;

  @media @tablet {
    font-size: @fs-2xl;
  }

  &::before {
    content: "“";
    font-size: 1.8em;
    position: absolute;
    right: 100%;
    top: 0;
    color: @color-content-inverted-active;

    margin-right: .15em;
    margin-top: -.15em;

    @media @tablet {
      font-size: 2.5em;
    }
  }
}

.c-quote__author {
  margin-top: 1em;

  font-size: @fs-xs;
  color: inherit;

  @media @tablet {
    margin-top: 1.75em;
  }

  &::before {
    content: none;
  }
}
