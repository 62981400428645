//
// Modal slider
// --------------------------------------------------

@sliderHeight: 15em;
@sliderHeightActive: 17em;
@sliderTrackHeight: 13em;

@modalBgColor: @color-content-inverted-light;

// Slider
.c-slider-modal {
  position: relative;
}

.c-slider-modal__nav {
  .l-container;
  margin-bottom: .5em;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.c-slider-modal__nav-item {
  .button-reset;
  transition: opacity @anim-short;

  &.swiper-button-disabled {
    opacity: .3;
    cursor: @cursor-disabled;
  }

  & + & {
    margin-left: .5em;
  }
}

.c-slider-modal__nav-svg {
  display: block;
  height: 2.25em;
  width: 2.25em;
}

.c-slider-modal__swiper {
  position: relative;

  &::after {
    content: '';
    height: @sliderTrackHeight;
    position: absolute;
    top: (@sliderHeightActive - @sliderTrackHeight) / 2;
    left: 0;
    right: 0;
    background-color: @color-gray-e;
  }
}

.c-slider-modal__items {
  height: @sliderHeightActive;
  align-items: center;
}

.c-slider-modal__item {
  width: auto;
  height: @sliderHeight;
  transition: height @anim-short;

  position: relative; // for modal click blocker

  &.swiper-slide-active,
  &.swiper-slide-duplicate-active {
    height: @sliderHeightActive;
  }

  // block modal clicks for non-active items
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10; // > linkarea
  }

  &.swiper-slide-active::before,
  &.swiper-slide-duplicate-active::before {
    display: none;
  }
}

.c-slider-modal__linkarea {
  display: block;
  height: 100%;
  position: relative; // for magnifier
  overflow: hidden;
}

.c-slider-modal__img {
  display: block;
  height: 100%;
  //max-width: 320px; // TODO: auto width (vw?)
  width: auto;
  object-fit: cover;
}

.c-slider-modal__magnifier {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translate3d(0, 0, 0);
  transition: all @anim-short;

  .swiper-slide-active &,
  .swiper-slide-duplicate-active & {
    transform: scale(1.5);

    &:hover,
    &:focus {
      transform: scale(1.6);
    }
  }
}

.c-slider-modal__magnifier-svg {
  display: block;
  width: 4.375em;
  height: 4.375em;
}

// Captions
.c-slider-modal__captions {
  margin-top: .75em;
  position: relative;

  transition: height @anim-short;
}

.c-slider-modal__captions--storytelling {
  margin-left: 4rem;

  @media @mobileLarge {
    margin-left: 0;
  }
}

.c-slider-modal__caption {
  margin: 0;

  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 0;

  width: calc(100% ~'-' 2rem);
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;

  opacity: 0;
  transition: opacity @anim-short;

  font-size: @fs-xs;
  text-align: center;

  @media @tablet {
    max-width: 22rem;
  }

  &.is-active {
    opacity: 1;
  }
}


// Modal
.c-slider-modal__modal {
  max-width: 650px;
  margin-left: @grid-gutter-width;
  margin-right: @grid-gutter-width;
  box-shadow: 0 7px 30px rgba(0, 0, 0, .3);
}

.fancybox--slider-modal {
  .fancybox-bg {
    background-color: @modalBgColor;
  }
}


