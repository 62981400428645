@facebook: #3b5998;
@twitter: #00a0d1;
@google: #D34836;
@pinterest: #910101;

.social-likes,.social-likes__widget {
	display:inline-block;
	vertical-align:middle;
	line-height:20px;
	text-indent:0;
	padding:0;
	border:0;
	word-spacing:0;
	font-size:13px;
	*zoom:1;
	*display:inline;
}

.social-likes {
	margin:3px;
	line-height:26px;
	list-style:none;
	display: block;
	text-align: center
}

.social-likes li {
	display:inline-block;
	*zoom:1;
	*display:inline;
	margin-left: .5em;
	margin-right: .5em;
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.social-likes__widget {
	display:inline-block;
	white-space:nowrap;
	padding: .5em .5em .3em;
	min-width: 130px
}

.social-likes__counter {
	font-size: 11px;
	padding-left: 1em;
	color: #fff;
}
.social-likes__counter:hover {
    color: #fff
}

.social-likes__icon {
	padding-right: .3em
}


.social-likes__widget_facebook {
	color: #ffffff;
	background: @facebook;
	cursor: pointer;
}

.social-likes__widget_facebook:hover {
	background: darken(@facebook, 10%);
	cursor: pointer;
}

.social-likes__icon_facebook:before {
	font-family: FontAwesome;
	content: "\f09a";
}

.social-likes__widget_twitter {
	color: #ffffff;
	background: @twitter;
	cursor: pointer;
}

.social-likes__widget_twitter:hover {
	background: darken(@twitter, 10%);
	cursor: pointer;
}

.social-likes__icon_twitter:before {
	font-family: FontAwesome;
	content: "\f099";
}

.social-likes__widget_plusone {
	color: #ffffff;
	background: @google;
	cursor: pointer;
}

.social-likes__widget_plusone:hover {
	background: darken(@google, 10%);
	cursor: pointer;
}

.social-likes__icon_plusone:before {
	font-family: FontAwesome;
	content: "\f0d5";
}

.social-likes__widget_pinterest {
	color: #ffffff;
	background: @pinterest;
	cursor: pointer;
}

.social-likes__widget_pinterest:hover {
	background: darken(@pinterest, 10%);
	cursor: pointer;
}

.social-likes__icon_pinterest:before {
	font-family: FontAwesome;
	content: "\f0d2";
}
