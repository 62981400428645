.fancybox-slide--iframe .fancybox-content {
  max-width: 80%;
  max-height: 80%;
}

.fancybox-caption {
  font-family: inherit;
  .font-smoothing;
}

.fancybox-is-open .fancybox-bg {
  opacity: .95;
}

// Close button
.fancybox__close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.fancybox__close {
  .font-serif;
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    transform: translateY(.05em); // magic number to perfectly center the text
  }
}

.fancybox__close-icon {
  font-size: 1.75em;
  margin-right: .5em;
}
