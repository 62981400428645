.c-person__cols {
  @media @tablet {
    display: flex;
    align-items: center;
  }
}

.c-person__info {
  margin-top: 2em;
  max-width: 20em;

  @media @tablet {
    margin-top: 0;
    margin-left: 2em;
  }
}

.c-person__avatar {
  height: 16em;
  width: 16em;
  border-radius: 50%;

  padding: .35em;
  border: .7em solid @color-gray-d;
}

.c-person__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.c-person__dummy {
  height: 100%;
  width: 100%;

  border-radius: 50%;
  background-color: @color-gray-e;
  color: rgba(0, 0, 0, .15);
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 8em;
  }
}

.c-person__headline {
  .h3();
  margin-bottom: .4em;
}

.c-person__position {
  padding-bottom: 1.3em;
  margin-bottom: 1.3em;
  position: relative; // for the separator line

  // The separator line
  &::after {
    .separator();
    content: '';
  }

  p {
    margin: 0;
    .font-serif;
    color: @color-content-inverted;
  }
}
