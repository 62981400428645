//
// Meta buttons
// --------------------------------------------------

@metaBtnSize: 1.5em;
@metaBtnSizeLg: 2.5em;

.btn-meta {
  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  line-height: @metaBtnSize;
  height: @metaBtnSize;
  min-width: @metaBtnSize;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10; // > logo

  &[data-state-hidden=true] {
    display: none;
  }

  & + & {
    margin-left: 1em;

    @media @tablet {
      margin-left: 1.5em;
    }
  }
}

.btn-meta--lg {
  line-height: @metaBtnSizeLg;
  height: @metaBtnSizeLg;
  min-width: @metaBtnSizeLg;

  transition: transform @anim-shortest;

  &:active {
    transform: translate(.125rem, .125rem);
  }

  & + & {
    margin-left: 0;

    @media @tablet {
      margin-left: 1.75em;
    }
  }
}

.btn-meta--uppercase {
  text-transform: uppercase;
}

.btn-meta--inverted {
  &,
  &:hover,
  &:focus {
    color: @color-content-inverted;
  }
}

.btn-meta__icon-svg {
  display: block;
  height: @metaBtnSize;
  width: @metaBtnSize;

  .btn-meta--lg & {
    height: @metaBtnSizeLg;
    width: @metaBtnSizeLg;
  }

  .btn-meta--lang & {
    height: 1.25em;
    width: 1.25em;
  }
}

.btn-meta__text {
  // this can be a link (see lang dropdown)
  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  font-size: @fs-xs;
  white-space: nowrap;
  margin-left: .5em;
  display: none;

  .btn-meta--lang & {
    display: block;
  }

  @media @tablet {
    display: block;
  }

  .btn-meta--lg & {
    font-size: @fs-sm;
    margin-left: .25em;
  }

  .btn-meta--menu & {
    .font-serif;
    font-size: @fs-2md;
    transform: translateY(.05em); // magic number to perfectly center the text
  }
}

.btn-meta__text--invisible {
  display: none;
}

.btn-meta__badge {
  position: relative;

  &::after {
    content: attr(data-count);
    background: @color-ci-red;
    color: @white;
    font-family: @font-family-monospace;
    .no-font-smoothing;

    position: absolute;
    top: -11%;
    right: -11%;
    border-radius: 50%;
    box-sizing: content-box;
    width: 12px;
    height: 12px;
    font-size: 9px;
    text-align: center;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
}

// Special language meta button (flag & dropdown)
.btn-meta--lang {
  &.open .icon-angle-down::before {
    content: "\f106";
  }

  .dropdown-menu {
    min-width: 0;
    font-size: @fs-xs;
  }
}

// Special search meta button
.btn-meta--search {
  margin-left: 0;
  margin-right: auto;

  @media @desktop {
    margin-left: auto;
    margin-right: 0;
  }
}
