//
// Grid
// --------------------------------------------------

@gridGutter: @grid-gutter-width;

.l-grid {
  margin-left: -@gridGutter / 2;
  margin-right: -@gridGutter / 2;

  @media @tablet {
    display: flex;
    flex-flow: row wrap;
  }
}

.l-grid--centered {
  @media @tablet {
    justify-content: center;
  }
}

// default size
.l-grid__item {
  padding: @gridGutter / 2;

  @media @tablet {
    width: 50%;
  }

  @media @desktop {
    width: 100% / 3;
  }
}

// double size (desktop only)
.l-grid__item--double {
  @media @desktop {
    width: (100% / 3) * 2;
  }
}

// triple size
.l-grid__item--triple {
  @media @desktop {
    width: 100%;
  }
}

// Grid Teaser
.c-grid-teaser {
  transition: all @gridTransition;
  height: 100%;

  &:hover {
    background-color: @color-gray-e;
    box-shadow: 0 7px 30px rgba(0, 0, 0, .3);
  }
}

// Makes grid teasers have equal height. TODO: Cross-browser testing
.c-grid-teaser__gateway,
.c-grid-teaser__tile,
.c-grid-teaser__teaser-icon {
  min-height: 100%;
}
