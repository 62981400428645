//
// Footer
// --------------------------------------------------

// Footer alpha
.c-footer__alpha {
  padding-top: 3.25em;
  padding-bottom: 3.25em;
  background-color: @color-content-inverted;
  color: @color-white;
  .font-smoothing;
}

.c-footer__cols {
  margin: 0 -@grid-gutter-width;

  @media @tablet {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden; // nasty hack to avoid negative margin causing overflow.
  }

  @media @desktop {
    flex-wrap: nowrap;
    overflow: visible;
  }
}

.c-footer__col {
  padding: 0 @grid-gutter-width;

  @media @tablet {
    flex-grow: 1;
    flex-basis: 0;
  }

  & + & {
    margin-top: 3em;

    @media @tablet {
      margin-top: 0;
    }
  }
}

.c-footer__col--links {
  @media @tablet {
    flex-basis: auto;
    width: 100%;
    margin-left: -@grid-gutter-width;
    margin-right: -@grid-gutter-width;

    display: flex;

    .c-footer__col + & {
      margin-top: 3em;
    }
  }

  @media @desktop {
    flex-basis: 0;
    width: auto;
    margin-left: 0;
    margin-right: 0;

    display: block;

    .c-footer__col + & {
      margin-top: 0;
    }
  }
}

.c-footer__box {
  // vertical on mobile, horizontal on tablet
  @media @tablet {
    flex-grow: 1;
    flex-basis: 0;
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;
  }

  // vertical on desktop
  @media @desktop {
    flex-grow: 0;
    flex-basis: auto;
    padding-left: 0;
    padding-right: 0;
  }

  // stacked boxes
  & + & {
    margin-top: 3em;

    @media @tablet {
      margin-top: 0;
    }

    @media @desktop {
      margin-top: 3em;
    }
  }
}

// Logo
.c-footer__logo {
  height: 110px;
  width: 260px;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.c-footer__logo-link {
  display: block;
}

.c-footer__logo-svg {
  display: block;
  height: 100%;
  width: 100%;
}

// Address
.c-footer__address {
  margin-bottom: 0;

  .c-footer__logo + & {
    margin-top: 1em;
  }

  a {
    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }
}

// Map
.c-footer__map {
  width: 100%;
  max-width: 18em;
  height: auto;

  a {
    display: block;
  }

  img {
    display: block;
  }
}

// Headline
.c-footer__headline {
  .h2;
  margin-bottom: .35em;
}

// Social links
.c-footer__social-list {
  margin: -.3em;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
}

.c-footer__social-icon {
  margin: .3em;

  a {
    color: @color-content;
    font-size: 1.7em;
    width: 1.5em;
    height: 1.5em;
    background-color: @color-white;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color @anim-short;

    &:hover,
    &:focus {
      color: @color-content;
      background-color: @color-gray-d;
    }
  }
}

// Newsletter
.c-footer__newsletter-btn {
  margin-top: .4em;

  &,
  &:focus,
  &:hover,
  &:active {
    border: 0 none;
  }
}


// Footer beta
.c-footer__beta {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  background-color: @color-content-inverted-active;
  color: @color-white;
  .font-smoothing;
}

// Meta nav
.c-footer__nav {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: @screen-xs) {
    justify-content: center;
  }

  li {
    a {
      &,
      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }


  li + li {
    &::before {
      content: '|';
      margin-left: .5em;
      margin-right: .5em;
      text-align: center;
    }
  }
}

// Footer gamma
.c-footer__gamma {
  padding-top: 1.25em;
  background-color: white;
  .font-smoothing;

  > .c-footer__container {
    @media @tablet {
      display: flex;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: .5rem;
  }
}

.c-footer__partner-logo {
  width: auto;
  height: 4rem;
  margin-left: .5rem;
  margin-bottom: 1.25em;
}

.c-footer__partner-logos--right {
  margin-top: 16px;

  @media @tablet {
    margin-left: 6rem;
    margin-top: 0;
  }
}
