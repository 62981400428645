//
// Quicksearch
// -------------------------

@quicksearch-layer-shadow: 0 14px 36px 2px rgba(0, 0, 0, .15);

.c-quickfinder {
  font-size: 1rem;

  // autocomplete dropdown, (overriding the definitions in forms.less)
  .autocomplete.dropdown-menu {
    width: 100% !important;
    border-radius: 0;
    border: 0 none;
    color: @color-content;
    box-shadow: @quicksearch-layer-shadow;
    margin-bottom: 2em; // for overflow scrolling
  }
}

// title above form
.c-quickfinder__title {
  display: flex;
  font-family: Oswald, sans-serif;
  font-weight: bold;
  font-size: 1.75em;
  text-transform: uppercase;
  margin-bottom: 2em;
  padding-right: 1em;

  .btn-meta__icon-svg {
    margin-right: .5em;
  }
}

// form
.c-quickfinder__field-wrapper {
  display: flex;
  margin-bottom: 2em;
  justify-content: space-between;

  .c-quickfinder__field {
    margin-top: 2px;
    width: calc(50% - 1em);
  }
}

.c-quickfinder__form--events {
  .c-quickfinder__field--dates {
    padding: .5em 1.25em;
  }

  .c-quickfinder__input {
    width: 100%;
  }

  .btn-meta__icon {
    display: flex;
  }
}

// field wrappers
.c-quickfinder__field {
  position: relative;
  background-color: @color-white;
  .font-smoothing;
  padding: 1.25em;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & ~ & {
    margin-top: 2px;
  }
}

.c-quickfinder__field--events {
  display: flex;
}

// label above inputs
.c-quickfinder__label {
  .font-serif;
  font-weight: @fw-bold;
  color: @color-content-inverted;
  display: block;
  text-transform: uppercase;
}

// input fields
.c-quickfinder__input {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  appearance: none;
  border-radius: 0;
  color: @color-content;
  font-size: 1.125em;
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  // TODO: find a better solution
  &:focus {
    outline: none;
  }
}

// datepicker input fields
.c-quickfinder__input--from,
.c-quickfinder__input--to {
  cursor: pointer;
  width: calc(~'50% - 15px');
}

// submit <button>
.c-quickfinder__field--submit {
  width: 100%;
  align-items: center;
  appearance: none;
  border: none;

  background-color: @color-content-inverted-active;
  color: @color-white;
  .font-serif;
  font-size: 1.5em;
  font-weight: @fw-bold;

  transition: all @anim-short;

  &:hover {
    background-color: @color-white;
    color: @color-content-inverted;
  }
}

// datepickers
.c-quickfinder__dates {
  display: flex;
  align-items: center;

  // override flatpickr JS positioning
  .flatpickr-calendar {
    top: calc(100% + 2px) !important;
    left: 0 !important;
    z-index: 5; // > fields, < guest layer
    margin-bottom: 2em; // for overflow scrolling
    width: 310px;

    // hide flatpickr indicator arrows
    &::before,
    &::after {
      display: none;
    }
  }
}

.c-quickfinder__dates--event {
  .flatpickr-days {
    width: 310px;
  }
}

// date field arrow
.c-quickfinder__arrow-icon {
  height: auto;
  width: 1.75em;
  margin-right: .625em;
}


// guest select layer
.c-quickfinder__guest-layer {
  position: absolute;
  right: 0;
  top: calc(100% ~"+" 2px);
  z-index: 10;// > fields, > flatpickr
  margin-bottom: 2em; // for overflow scrolling

  background-color: @color-white;
  color: @color-content;
  box-shadow: @quicksearch-layer-shadow;
  border-top: none;
  width: 20em;
  max-width: 100%;
  padding: 1.25em;

  &[data-state=open] {
    display: block;
  }

  &[data-state=closed] {
    display: none;
  }

  label {
    font-weight: @fw-regular;
  }
}

// guest layer toggler text
.c-quickfinder__text {
  display: inline-block;
  color: @color-content;
  font-size: 1.125em;
  cursor: pointer;

  position: relative;

  &::after {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    content: '\f107';
    font-family: 'FontAwesome';
    line-height: 1;
    font-size: 1.5em;
    color: @color-content-inverted;
  }
}

// guest select component
.c-qf-guest-select {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 1.25em;
  }
}

.c-qf-guest-select__button-box {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: .5em;
  font-size: .8em;

  @media @mobileLarge {
    font-size: 1em;
  }
}

.c-qf-guest-select__button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid currentColor;
  font-size: 1em;

  height: 3.125em;
  width: 3.125em;
  margin: 0 .75em;

  &,
  &:hover,
  &:focus {
    color: @color-content-inverted;
  }
}

.c-qf-guest-select__button--disabled {
  cursor: @cursor-disabled;

  &,
  &:hover,
  &:focus {
    color: @color-gray-c;
  }
}

.c-qf-guest-select__button--add {
  margin-right: 0;
}

.c-qf-guest-select__button--remove {
  margin-left: 0;
}

.c-qf-guest-select__value {
  color: @color-content;
  font-size: 1.5em;
  font-weight: @fw-bold;
  min-width: 1.25em;
  text-align: center;
}

.c-qf-guest-select__label {
  font-weight: @fw-regular;
}

.c-qf-guest-select__confirm {
  display: block;
  margin-top: .625em;
  text-align: right;
  text-transform: uppercase;
  font-weight: @fw-bold;
  .font-serif;
}

.c-qf-guest-select__children {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.75em;
}

.c-qf-guest-select__tportal-children {
  display: none;
  width: calc(~'50% - 10px');

  &:nth-child(2n+1) {
    margin-right: 10px;
  }

  &:nth-child(2n) {
    margin-left: 10px;
  }

  select {
    appearance: none;
    border-radius: 0;
    box-shadow: none;
    font-size: 1em;
    background-image: url(/extension/portal-ngh/design/ngh-de/images/quicksearch/caret-down.svg);
    background-repeat: no-repeat;
    background-position: calc(~'100% - 10px') 50%;
    background-size: 10px 10px;

    &::-ms-expand {
      display: none;
    }
  }
}
