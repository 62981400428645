// ez tables
// -------------------------

.c-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media @desktopLarge {
    overflow: visible;
  }
}

.c-table {
  width: 100%;
}

.c-table:not(.table) .c-table__tr {
  td,
  th {
    padding: .5em;

    i {
      font-size: .8em;
    }
  }

  td {
    border-bottom: 1px solid @color-gray-c;
  }

  th {
    font-size: @fs-md;
    border-bottom: 2px solid @color-content-inverted;
  }
}
