//
// TPX (tportal external) styles
// --------------------------------------------------

// using theme-variable mapping to ensure some modularity

@priceColor: @brand-ci;
@priceFontSize: @font-size-large;

@ribbonBgColor: #a00;
@ribbonFrontColor: white;

@packageListIconFontFamily: 'FontAwesome';
@packageListIconChar: '\F00C';
@packageListIconColor: @brand-ci;


// Popovers (Tooltips)
// -------------------------
.tpx-tooltip-content { /* stylelint-disable-line block-no-empty */
}

// Ribbon
// Must be placed into a container which has position:relative; & overflow:hidden; applied.
// -------------------------
.tpx-ribbon {
  background-color: @ribbonBgColor;
  overflow: hidden;
  position: absolute;
  left: -50px;
  top: 9px;
  transform: rotate(-45deg);
  z-index: 10;

  span {
    color: @ribbonFrontColor;
    display: block;
    font-size: 11px;
    font-weight: 600;
    margin: 1px;
    padding: 6px 0;
    text-align: center;
    width: 150px;
  }
}

.tpx-ribbon-wrapper {
  overflow: hidden;
  position: relative;
}

// Classification
// -------------------------
.tpx-classification {
  display: inline-block;
}

.tpx-classification__classification + .tpx-classification__classification {
  margin-left: .5em;
}

// Decorations
// -------------------------
@decorationPad: 5px;

.tpx-decorations {
  display: flex;
  margin: 0 -@decorationPad;
}

.tpx-decorations__wrapper {
  display: inline-block;
  margin: 0 @decorationPad;
}

.tpx-decorations__img {
  display: block;
  max-height: 35px;
  width: auto;
}

// Subline
// -------------------------
.tpx-subline { /* stylelint-disable-line block-no-empty */
}

// Address
// -------------------------
.tpx-address { /* stylelint-disable-line block-no-empty */
}

.tpx-address__item {
  display: block;
}

// Description
// -------------------------
.tpx-description { /* stylelint-disable-line block-no-empty */
}

// package list icon
.tpx-description--included-services {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &::before {
        font-family: @packageListIconFontFamily;
        content: @packageListIconChar;
        display: inline-block;
        color: @packageListIconColor;
        margin-right: .3em;
      }
    }
  }
}

// Price
// -------------------------

.tpx-price-wrapper {
  display: flex;
  align-items: center;
}

.tpx-price {
  strong {
    font-weight: normal;
  }
}

.tpx-price__value {
  color: @priceColor;
  font-weight: bold;
  font-size: @priceFontSize;
}

.tpx-price-popover {
  margin-left: .4em;
}

// Links
// -------------------------
@linkPad: 2px;

.tpx-links-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -@linkPad -@linkPad -@linkPad;
}

.tpx-link {
  margin: @linkPad;
}

// Cross-field
// -------------------------
.tpx-classification ~ .tpx-address,
.tpx-subline ~ .tpx-address {
  margin-top: 10px;
}

.tpx-subline ~ .tpx-description,
.tpx-address ~ .tpx-description {
  margin-top: 10px;
}

.tpx-subline ~ .tpx-price-wrapper,
.tpx-address ~ .tpx-price-wrapper,
.tpx-description ~ .tpx-price-wrapper {
  margin-top: 15px;
}
