//
// Overlays (search, quicksearch, nav)
// --------------------------------------------------

@overlayBoxShadowOffset: 7px;
@overlayBoxShadowSpread: 20px;

.ngh-overlay {
  position: fixed;
  z-index: @zindex-navbar-fixed; // relies on sitting after topbar in HTML structure
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 27.5em;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  background-color: @color-content-inverted;
  color: @color-white;
  .font-smoothing;

  box-shadow: @overlayBoxShadowOffset 0 @overlayBoxShadowSpread rgba(0, 0, 0, .2);

  display: flex;
  flex-flow: column;

  opacity: .75;
  transform: translateX(-100%) translateX(-@overlayBoxShadowOffset - @overlayBoxShadowSpread);
  transition: all .3s;
  // Out: Sharp
  transition-timing-function: cubic-bezier(.4, 0, .6, 1);

  &[data-state="open"] {
    // In: Deceleration
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    opacity: 1;
    transform: none;
  }
}

.ngh-overlay--right {
  left: auto;
  right: 0;
  box-shadow: -@overlayBoxShadowOffset 0 @overlayBoxShadowSpread rgba(0, 0, 0, .2);
  transform: translateX(100%) translateX(@overlayBoxShadowOffset + @overlayBoxShadowSpread);
}

.ngh-overlay--sub {
  z-index: 1;
  background-color: @color-content-inverted-active;
  box-shadow: none;
}

.ngh-overlay__top {
  flex-shrink: 0;
  border-bottom: 1px solid @color-white;
  height: @topbarHeight;
  display: flex;
  align-items: flex-end;
  padding: 1em;

  @media @tablet {
    padding: 1em 2em;
    height: @topbarHeightSM;
  }
}

.ngh-overlay__toplink {
  color: inherit;
  .font-serif;
  display: flex;
  align-items: center;

  transition: transform @anim-shortest;

  &:active {
    transform: translate(.125rem, .125rem);
  }

  &:hover,
  &:focus {
    color: inherit;
  }
}

.ngh-overlay__toplink--primary {
  span {
    text-transform: uppercase;
    font-size: @fs-2md;
    transform: translateY(.05em); // magic number to perfectly center the text
  }
}

.ngh-overlay__toplink--secondary {
  span {
    font-size: @fs-xs;
  }
}

.ngh-overlay__toplink-icon {
  font-size: 1.75em;
  margin-right: .5em;
}

.ngh-overlay__content {
  flex-grow: 1;

  display: flex;
  flex-flow: column;
}

.ngh-overlay__content--spacing-md {
  padding: 2em 1em;

  @media @mobileLarge {
    padding: 2.25em;
  }
}

.ngh-overlay__content--spacing-lg {
  padding: 2em 1em;

  @media @mobileLarge {
    padding: 3em;
  }
}

.ngh-overlay__bottom {
  background-image: linear-gradient(to right, rgba(0, 0, 0, .25), transparent);
  padding: 1em;
  margin-bottom: 1em;
  margin-top: auto;
  flex-shrink: 0;

  display: flex;

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  @media @tablet {
    padding: 1em 2em;
  }
}
