//
// Gateway
// --------------------------------------------------

@gatewayHeightXS: 18.75em; // 300px
@gatewayHeightS: 29em; // 464px
@gatewayHeightM: 32em; // 512px
@gatewayHeightL: 35em; // 560px
@gatewayTransition: @gridTransition;

// Default: S
.gateway {
  font-size: 1rem;
  background-color: @color-gray-c;
  height: @gatewayHeightS;
  position: relative;
  overflow: hidden; // for scaled image
}

// XS
.gateway--XS {
  height: @gatewayHeightXS;
}

// M
.gateway--M {
  height: @gatewayHeightM;
}

// L
.gateway--L {
  height: @gatewayHeightL;
}

// Fullscreen
.gateway--full {
  height: @fullscreenHeight;

  @media @tablet {
    height: @fullscreenHeightSM;
  }
}


// Linkarea
.gateway__linkarea {
  display: block;
  height: 100%;
  padding: 2em 1em;

  @media @tablet {
    padding: 2em;
  }

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  // Bottom shadow modifier
  .gateway--bottom-shadow & {
    &::after {
      content: '';
      position: absolute;
      z-index: 5; // > image, < content
      bottom: 0;
      left: 0;
      right: 0;
      height: 1.25em;
      background-color: rgba(255, 255, 255, .3);
    }
  }

  // Background shadow
  &::before {
    content: '';
    position: absolute;
    z-index: 5; // > image, < content
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    background-image: linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, 0) 60%);
    transition: opacity @anim-std;
    opacity: .4;

    .gateway--hover:hover & {
      opacity: .8;
    }

    // no bottom shadow for no-content gateways (e.g. big teaser)
    .gateway--no-content & {
      display: none;
    }
  }
}

// Image
.gateway__img {
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: all @gatewayTransition;

  .gateway--hover & {
    transform: scale(1.02);
  }

  .gateway--hover:hover & {
    transform: scale(1);
  }

  &[data-gateway-fade-in] {
    opacity: 0;
    visibility: hidden;
  }

  &[data-gateway-fade-in].has-loaded {
    opacity: 1;
    visibility: visible;
  }
}

// Loader
.gateway__loader {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/extension/portal-ngh/design/ngh-de/images/loader/oval.svg');
  background-size: 3em;
  display: none;

  [data-gateway-fade-in]:not(.has-loaded) + & {
    display: block;
  }

  .gateway--loader-bottom & {
    background-position: center calc(100% ~"-" 1em);
  }

  .gateway--loader-top & {
    background-position: center 4em;
  }
}

// Content box
.gateway__content {
  position: relative;
  z-index: 10; // > image
  height: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
}

// Icon
.gateway__icon {
  margin-bottom: .25em;
  transform: translate3d(0, 0, 0);
  transition: transform @gatewayTransition;

  .gateway--hover:hover & {
    transform: translateY(-1rem);
  }
}

.gateway__icon-svg {
  display: block;
  height: 4em;
  width: 4em;

  .gateway__icon--logo & {
    height: 4.5em;
    width: 11.5em;
  }
}

// Title
.gateway__title {
  .h2();

  // larger headlines for larger gateways
  .gateway--L &,
  .gateway--full & {
    .h1();
  }

  // dealing with the cascade here, unfortunately
  &,
  .gateway--L &,
  .gateway--full & {
    color: @white;
    .font-smoothing();

    padding-bottom: .4em;
    margin-bottom: .4em;
  }

  hyphens: auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);

  display: block;
  width: 100%; // IE 11 fail
  text-align: center;
  position: relative; // for the separator line

  transform: translate3d(0, 0, 0);
  transition: transform @gatewayTransition;

  .gateway--hover:hover & {
    transform: translateY(-.6rem);
  }

  // The separator line
  &::after {
    .separator();
    content: "";
    transform: translateY(50%);
    margin-left: auto;
    margin-right: auto;
  }
}

// Subline
.gateway__subline {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  color: @white;
  .font-smoothing();
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);

  transform: translate3d(0, 0, 0);
  transition: transform @gatewayTransition;

  .gateway--hover:hover & {
    transform: translateY(-.3rem);
  }
}

// Scrollhint
.gateway__scrollhint {
  .gateway__subline + &,
  .gateway__title + & {
    margin-top: 1.5em;
  }

  display: block;

  transform: translate3d(0, 0, 0);
  transition: transform @gatewayTransition;

  .gateway--hover:hover & {
    transform: translateY(-.3rem);
  }
}

.gateway__scrollhint-svg {
  display: block;
  height: 3em;
  width: 3em;

  animation: bounceArrow 2s infinite;
}

@keyframes bounceArrow {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-.8em);
  }

  60% {
    transform: translateY(-.4em);
  }
}

// Magnifier
.gateway__magnifier {
  align-self: flex-end;

  transform: translate3d(0, 0, 0);
  transition: all @anim-short;

  .gateway__linkarea:hover &,
  .gateway__linkarea:focus & {
    transform: scale(1.1);
  }
}

.gateway__magnifier-svg {
  display: block;
  width: 4.375em;
  height: 4.375em;
}

// Siteaccess hero
.gateway__siteaccess {
  height: 100%;
  width: 100%;
  padding: 2.5em 4em;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  // We want the text image to appear slightly above the center point on tall screens.
  // Since align-items doesn't take a percentage value, we need this workaround for that.
  &::after {
    content: '';
    height: 20%;
    flex-shrink: 9999999;
  }
}

.gateway__siteaccess-svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 12.5em;
  height: 17em;

  @media @tablet {
    width: 15.5em;
    height: 20.5em;
  }
}

.gateway__copyright {
  color: white;
  font-size: .8125rem;
  position: absolute;
  right: .75rem;
  bottom: .5rem;
  z-index: 15;
  text-shadow: 0 0 8px rgba(0, 0, 0, .16);
}
