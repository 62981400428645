.no-focus() {
  outline: 0 !important;
  appearance: none;
  box-shadow: none !important;
}

.font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-font-smoothing() {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.font-serif() {
  font-family: @ff-serif-fallback;

  // fonts-loaded body flag, triggered by JS (fontfaceobserver)
  .has-loaded-fonts-body & {
    font-family: @ff-serif;
  }
}

.font-ci() {
  font-weight: @fw-bold;
  font-family: @ff-ci-fallback;

  // fonts-loaded body flag, triggered by JS (fontfaceobserver)
  .has-loaded-fonts-ci & {
    font-family: @ff-ci;
  }
}


// The separator line
.separator() {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: .375rem;
  width: 11rem;
  max-width: 100%;
  background-color: @color-content-inverted;
}


// Reset all default button styles
.button-reset() {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  white-space: normal;

  &:focus {
    outline: none;
  }
}
