// Error page styles
// -------------------------

.full_error {
  text-align: center;
}

.fourohfour {
  .font-ci;
  font-size: 6.25em;
  text-shadow: 2px 0 0 @color-ci-red-dark;
  color: @color-ci-red;
  border-bottom: 4px solid @black;
  margin-bottom: .5em;
  display: inline-block;
}
