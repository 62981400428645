//
// Scaffolding
// --------------------------------------------------

html {
  font-size: @font-size-base;
  -webkit-text-size-adjust: 100%; /* stylelint-disable-line property-no-vendor-prefix */
  -ms-text-size-adjust: 100% /* stylelint-disable-line property-no-vendor-prefix */
}

body {
  // fallback font for initial render to avoid FOIT
  font-family: @ff-sans-fallback;

  // fonts-loaded flag, triggered by JS (fontfaceobserver)
  &.has-loaded-fonts-body {
    font-family: @ff-sans;
  }
}

::selection {
  background: @color-content-inverted;
  color: @white;
}

