.c-teaser-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2em 1.5em;

  &,
  &:hover,
  &:focus {
    color: inherit;
  }
}

.c-teaser-icon__svg {
  display: block;
  height: 7.5em;
  width: 7.5em;
}

.c-teaser-icon__headline {
  .c-teaser-icon__icon + & {
    margin-top: .75em;
  }
}

.c-teaser-icon__infotext {
  margin-top: .75em;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.c-teaser-icon__link {
  margin-top: 1em;
}

.c-teaser-icon__link-svg {
  display: block;
  height: 2em;
  width: 2em;
}
