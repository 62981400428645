//
// Gallery
// --------------------------------------------------

.c-gallery__items {
  display: flex;
  flex-wrap: wrap;

  margin-left: -@gridGutter / 2;
  margin-right: -@gridGutter / 2;
}

.c-gallery__item {
  width: 100%;
  padding: @gridGutter / 2;

  @media @mobileLarge {
    width: 100% / 2;
  }

  @media @desktop {
    width: 100% / 3;
  }
}

.c-gallery__item--primary {
  width: 100%;
}

.c-gallery__item--hidden {
  display: none;
}

.c-gallery__linkarea {
  display: block;
  width: 100%;
  height: 15em;
  position: relative; // for magnifier
  overflow: hidden;

  .c-gallery__item--primary & {
    @media @tablet {
      height: 20em;
    }
  }
}

.c-gallery__img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.c-gallery__magnifier {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: all @anim-short;

  .c-gallery__item--last-visible & {
    opacity: 1;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
    opacity: 1;
  }
}

.c-gallery__magnifier-svg {
  display: block;
  width: 4.375em;
  height: 4.375em;
}
