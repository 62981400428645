// Cookie consent custom CSS
// -------------------------

.cc-window {
  font-family: inherit;
  font-weight: @fw-semibold;
  font-size: .75em;
  .font-smoothing;

  color: @color-white;
  background-color: rgba(0, 0, 0, .9);

  transition: all @anim-short;

  @media @tablet {
    font-size: .9em;
    background-color: rgba(0, 0, 0, .6);

    &:hover {
      background-color: rgba(0, 0, 0, .9);
    }
  }
}

// opt-in type
.cc-banner.cc-bottom {
  bottom: @grid-gutter-width;
  left: @grid-gutter-width;
  right: @grid-gutter-width;
  width: auto;
}

.cc-bottom {
  bottom: 2rem;
}

.cc-right {
  right: 2rem;
}

.cc-left {
  left: 2rem;
}

.cc-btn {
  white-space: normal;
  transition: transform @anim-shortest;

  &,
  &:hover,
  &:focus {
    color: inherit;
    background-color: transparent;
  }

  &:active {
    transform: translate(.125rem, .125rem);
  }
}

.cc-link {
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: underline;
  }
}

.cc-btn:first-child {
  border-color: inherit !important;
}

@media screen and(min-width: 680px) {
  .cc-compliance {
    margin-left: 1rem;
  }
}
