//
// Richtext
// --------------------------------------------------

.c-richtext {
  img {
    max-width: 100%;
    height: auto;
    display: block;

    &:not(:first-child) {
      margin-bottom: 4em;
    }

    &:not(:first-child) {
      margin-top: 4em;
    }
  }

  p {
    margin-bottom: 0;
  }

  p,
  table {
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  a {
    font-weight: @fw-bold;
  }

  .embed_file {
    svg {
      width: 2em;
      height: 2em;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

.c-richtext--intro {
  font-weight: @fw-semibold;
  font-size: @fs-md;
}
