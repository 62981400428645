//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  .font-ci();
  font-weight: @fw-bold;
  line-height: @lh-sm;
  color: inherit;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

h1,
.h1 {
  font-size: @fs-4xl;

  @media @tablet {
    font-size: @fs-5xl;
  }
}

h2,
.h2 {
  font-size: @fs-2xl;

  @media @tablet {
    font-size: @fs-3xl;
  }
}

h3,
.h3 {
  font-size: @fs-xl;
}
