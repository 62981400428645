//
// Main search
// --------------------------------------------------

@searchHeight: 3.125rem;

.c-search__field {
  width: 100%;
  height: @searchHeight;
  min-width: 0;

  background: @color-white;
  border: 0 none;
  border-radius: @border-radius-base;
  margin: 0;
  padding: 0 @searchHeight 0 1em;
  appearance: none;
  .no-focus;

  .font-serif;
  font-weight: @fw-semibold;
  font-size: @fs-md;
  color: @color-content;

  &::placeholder {
    color: @color-gray-c;
    font-weight: @fw-semibold;
  }
}

.c-search__submit {
  .button-reset;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: @searchHeight;
  height: 100%;

  border-top-right-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;

  transition: background-color @anim-short;

  &:hover,
  &:focus {
    background-color: @color-gray-e;
  }
}

.c-search__submit-svg {
  display: block;
  width: 80%;
  height: 80%;
  margin-left: auto;
  margin-right: auto;
}
