//
// Product overrides (mostly legacy)
// --------------------------------------------------

.thumbnail {
  border: none;
  padding: 0;
  transition: none;
}

.price-value {
  font-weight: @fw-bold;
}

.opentime,
.opentime.opentime_get,
.opentime.opentime_get_toubiz {
  font-size: .875em;
  margin: .5em 0 0 !important;
  min-height: initial !important;
}

.full_archive .thumbnail_filter > .caption,
.full_ezfind_search .facet_box > .thumbnail {
  border: 1px solid #dedede;
}

.ajax-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  z-index: 20;
}

.thumbnail .panel-collapse.caption {
  padding: 15px;
}

// archive
.full_archive {
  position: relative;
  min-height: 400px;

  .thumbnail_filter {
    // headlines
    h3 {
      font-size: @fs-md;
      font-weight: @fw-bold;
      margin-bottom: .75em;
    }

    .btn-block + h3 {
      margin-top: 2em;
    }
  }

  // city filter
  .thumbnail_filter_city {
    padding-top: .75em;
    padding-bottom: .75em;
  }

  .children_switch_city {
    margin: 1.5em 0;
  }

  #tdb_city_az_select {
    float: none;
  }
}

// Search/archive facets
.facet_box {
  > .thumbnail {
    background-color: @color-gray-e;
  }

  .thumbnail .caption {
    padding: 15px;
  }
}

// product fullviews
.full_tdb_attraction,
.full_tdb_tour,
.full_tdb_city,
.full_lisgastro,
.full_lisdirekt {
  // headline styles
  h1 {
    &:extend(.h2);
    margin-bottom: .25em;
  }

  h2 {
    &:extend(.h3);
    margin-bottom: .5em;
  }

  .full_intro {
    font-weight: @fw-regular;
  }

  .opentime + .full_intro {
    margin-top: 1.5em;
  }

  // richtext
  // TODO: this might break some sidebar thumbs etc. keep an eye on it.
  .thumbnail_content .caption {
    .c-richtext;
  }

  // panels
  .thumbnail_content .panel-heading {
    font-size: @fs-md;
    color: @color-content;

    > a {
      color: @color-content;
    }
  }

  // map layer
  .full_geo {
    margin-top: 2em;
  }

  // lol
  p:empty {
    display: none;
  }

  .geo_close_by_items {
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    p {
      margin-bottom: 0 !important;
    }

    a {
      color: @color-gray-3;
      font-weight: @fw-bold;
    }

    .opentime-line-break {
      display: inline;
    }
  }

  .thumbnail_aside {
    background-color: @color-gray-e;

    .caption {
      padding: 1em;

      @media @tablet {
        padding: 1.5em;
      }
    }

    ul {
      margin-bottom: 0;

      > li:last-of-type {
        margin-bottom: 0;
      }

      > li:not(:last-of-type) {
        border-bottom: 1px solid @color-gray-d;
      }

      hr:last-of-type {
        display: none;
      }
    }
  }
}

.full_tdb_city {
  .full_head {
    margin-bottom: @line-height-computed;
  }

  .thumbnail_map {
    margin-top: @line-height-computed * 2;
  }

  .map_direction {
    padding: 1em 0 0;
    font-size: .875em;
  }
}

.full_lisgastro {
  .geo_close_by_items {
    margin-bottom: 0;
  }
}

.full_ezfind_search #facet_search #Orte {
  display: none;
}


/** product fullview navbar **/

.navbar {
  &:extend(.navbar-default all);
  .font-smoothing;
  border: 0 none;
  display: none;

  @media @tablet {
    display: block;
  }

  &.affix {
    top: 0;
    left: @grid-gutter-width;
    right: @grid-gutter-width;
    max-width: @container-lg;
    margin: 0 auto;
    z-index: @zindex-navbar-fixed - 5; // < overlay and topbar
  }

  > .container {
    @media @tablet {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}

.order_button_text + h2 {
  margin-top: 6rem;
}

