// Lisopenweather (mostly legacy)
// ---------------------------------------

@font-face {
  font-family: 'MeteoconsRegular';
  font-weight: normal;
  font-style: normal;
  src: url('/extension/portal-ngh/design/ngh-de/font/meteocons/meteocons-webfont.woff') format('woff');
}

.meteocons::before {
  font-family: 'MeteoconsRegular';
  font-style: normal;
  font-size: 140px;
  content: attr(data-icon);
}

i.meteocons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: @color-content-inverted;
}

.thumbnail_lisopenweather {
  .lisopenweather-image {
    background-color: transparent;

    .ajax-spinner {
      height: 5em;
      width: 5em;
      top: 50%;
      left: 50%;
      margin-left: -2.5em;
      margin-top: -2.5em;
      border-radius: .625em;
      position: absolute;
      z-index: 10;

      background-color: @color-content-inverted;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('/extension/portal-ngh/design/ngh-de/images/loader/oval.svg');
      background-size: 3em;
    }
  }

  .lisopenweather-temp {
    color: @color-content-inverted;
    font-size: @fs-lg;
    font-weight: @fw-bold;
  }
}
