.u-linebreak {
  display: table;
}

// invisible class - visibly hides its contents (no display:none;)
.u-invisible {
  margin: -3px;
  position: absolute;
  text-indent: 100%;
  overflow: hidden;
  height: 1px;
  width: 1px;
  display: block;
}
