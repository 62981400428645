.c-journeylink {
  display: flex;

  .font-ci;
  text-transform: uppercase;
}

.c-journeylink__icon {
  margin-right: 1em;
  margin-top: .5em;
}

.c-journeylink__svg {
  width: 2em;
  height: 2em;
  display: block;
}

.c-journeylink__text {
  line-height: 1.3;
  font-size: @fs-md;

  span {
    display: block;

    &:first-of-type {
      font-size: @fs-lg;
      line-height: 1.5;
    }
  }
}
