//
// Grid container
// --------------------------------------------------

.grid-container__items {
  .grid-container__head + & {
    margin-top: 3em;
  }

  & + & {
    margin-top: 3em;
  }
}

.grid-container__button {
  text-align: center;
  margin-top: 3.75em;

  .grid-container__items--icon-teasers + & {
    margin-top: 2em;
  }
}
