// Outdated Browser panel
// -------------------------
.outdated-browser {
  left: 50%;
  margin-left: -300px;
  margin-top: -55px;
  position: fixed;
  top: 50%;
  width: 600px;
  z-index: @zindex-outdated-browser;

  display: none;

  .is-outdated-browser & {
    display: block;
  }
}
