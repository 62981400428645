// LIS debug
// -------------------------
.lisdebug {
  overflow: hidden;
  margin-top: 50px;
}


// IAS Loader
// -------------------------
.ias_loader {
  border-radius: 10px;
  width: 90px;
  padding: 10px;
  background: rgba(0, 0, 0, .85);
  border-bottom: 3px solid @color-black;
  position: fixed;
  z-index: 1;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: .95;
}

.ias_trigger {
  clear: both; // if used inside classic grid
  width: 100%; // if used inside flex grid
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, .1);

  display: flex;
  justify-content: center;

  a {
    .font-smoothing();
    background: @brand-primary;
    color: @color-white;
    padding: 10px 15px;
    transition: all @anim-short;

    &::after {
      font-family: 'FontAwesome';
      content: "\F063";
      vertical-align: text-top;
      margin-left: 10px;
    }

    &:hover {
      transform: translate(0, 3px);
      opacity: .95;
    }
  }
}

// Spacing
// -------------------------
.spacing-top--md {
  margin-top: 50px;
}

.ml-0 {
  margin-left: 0 !important;
}
