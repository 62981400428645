.o-icon {
  fill: currentColor;
  height: 1em;
  width: 1em;
  overflow: hidden;
}

.o-icon--lg {
  height: 3em;
  width: 3em;
}

.o-icon--fitparent {
  height: 100%;
  width: 100%;
}
