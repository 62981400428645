//
// Standard Page Gallery (Magnific Popup)
// --------------------------------------------------

@galleryImageMaxHeight: 600px;
@galleryTextColor: @color-content;
@galleryMoreBgColor: @color-gray-e;

.gallery-page {
  text-align: center;
  margin-bottom: 2.5em;
}

.gallery-page--noSpace {
  margin: 0;
}

.gallery-page__main-img {
  a {
    display: block;
    position: relative;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .caption {
    text-align: left;
    padding-top: 4px;
    font-size: @font-size-small;
  }
}

.gallery-page .external_image .gallery-page__main-img img {
  max-height: @galleryImageMaxHeight;
  width: auto;
}


.gallery-page__more {
  background-color: #f2f2f2;
  color: @galleryTextColor;
  height: 60px;
  font-size: 22px;
  font-weight: 700;
  left: 2px;
  line-height: 60px;
  position: absolute;
  text-shadow: 1px 1px 1px #fff;
  top: 5px;
  width: 90px;
}

.gallery-page__thumbs {
  display: flex;
  justify-content: center;
  margin-bottom: -4px;
}

.gallery-page__thumb {
  margin: 6px 3px;
  display: inline-block;

  img {
    vertical-align: middle;
    max-height: 60px;
  }
}

.gallery-page__thumb--more {
  width: 93px;
  line-height: 60px;
  background-color: @galleryMoreBgColor;
  vertical-align: middle;
  color: @galleryTextColor;
  font-size: 18px;
}

.gallery-page__more-mobile {
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: @color-white;
  font-size: 2em;
  z-index: 1;
}
