//
// Topbar
// --------------------------------------------------

// total topbar heights
@topbarHeight: @topbarHeightPrimary + @topbarHeightSecondary;
@topbarHeightSM: @topbarHeightPrimarySM + @topbarHeightSecondarySM;

// primary topbar heights
@topbarHeightPrimary: 3.75em;
@topbarHeightPrimarySM: 5.5em;

// secondary topbar heights
@topbarHeightSecondary: 2em;
@topbarHeightSecondarySM: 2.25em;

@topbarTransitionTime: .6s;

.topbar {
  transform: translate3d(0, 0, 0);
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  z-index: @zindex-navbar-fixed;
  height: @topbarHeight;

  @media @tablet {
    height: @topbarHeightSM;
  }
}

.topbar--top::after {
  transition: none;
}

// Headroom modifiers
.topbar--headroom-initialized {
  transition: transform @topbarTransitionTime ease;
}

.topbar--top {
  transition: none;
}

.topbar--unpinned {
  transform: translateY(-100%);

  // animate shadow
  &::after {
    transform: translateY(-10em);
  }
}

// Topbar content
.topbar__secondary {
  background-color: @color-gray-e;
  height: @topbarHeightSecondary;

  @media @tablet {
    height: @topbarHeightSecondarySM;
  }

  .topbar.is-dropdown-open & {
    position: relative;
    z-index: 20; // > topbar__primary (for language dropdown)
  }
}

.topbar__secondary-inner {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.topbar__primary {
  position: relative;
  z-index: 10; // > shadow
  background-color: @color-white;
  border-bottom: 1px solid @color-content-inverted;
  height: @topbarHeightPrimary;

  transition: transform @topbarTransitionTime ease-out;

  .topbar--top & {
    transition: none;
  }

  .topbar--unpinned & {
    transform: translateY(-100%);
  }

  @media @tablet {
    height: @topbarHeightPrimarySM;
  }
}

.topbar__primary-inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar__push {
  margin-left: auto;
}

// perfectly centered >= tablet
.topbar__logo {
  height: 100%;
  width: auto;
  margin-right: 0;

  position: absolute;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  padding-top: .3em;
  padding-bottom: .3em;

  @media @tablet {
    top: 0;
    bottom: 0;

    padding-top: .5em;
    padding-bottom: .5em;
  }
}

.topbar__logo-link {
  display: block;
}

.topbar__logo-svg {
  display: block;
  height: 100%;
  width: 100%;
}
