//
// Form elements (mostly legacy)
// --------------------------------------------------

.form-control:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2) inset;
  border-color: #ccc;
}

.form_lupe {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 8px;
  height: @input-height-base;
  line-height: @input-height-base;
  z-index: 5;
  font-size: @font-size-large;
  color: inherit;
}


// Datepicker
// -------------------------
.datepicker {
  cursor: pointer;
}

.datepicker__wrapper {
  position: relative;
}

.datepicker__icon {
  position: absolute;
  font-size: 18px;
  bottom: 50%;
  right: 10px;
  transform: translate(0, 50%);
  pointer-events: none;
  cursor: pointer;
}


// Autocomplete Dropdown
// -------------------------
.autocomplete.dropdown-menu {
  max-height: none !important;

  .dropdown-menu-item {
    padding: 3px 1em;
    cursor: pointer;

    &.active {
      background: @color-gray-e;
    }
  }
}
