//
// Line (mostly legacy)
// --------------------------------------------------

.line {
  & + & {
    position: relative;
    margin-top: 60px;

    &::before {
      content: '';
      position: absolute;
      top: -30px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: @gray-lighter;
    }
  }

  .opentime {
    margin-top: 0;
  }

  .image img:not(.decoration_thumb) {
    width: 100%;
  }
}

.line_left {
  padding-right: @grid-gutter-width / 2;
}

.line_right {
  padding-left: @grid-gutter-width / 2;
}

.line_body {
  overflow: hidden;
  padding: 0;

  h2 {
    &:extend(.h3);
    margin-bottom: .25em;
    color: inherit;

    a {
      color: inherit;

      &:hover,
      &:focus {
        color: @color-content-inverted;
      }
    }
  }
}

// Dummy image
.line_dummy_img {
  background: @color-gray-3;
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;

  i {
    color: #fff;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -54px;
    margin-top: -55px;
  }

  .line_webcam .image {
    position: relative;
  }
}

.webcam_enlarge {
  color: @color-white;
}

@media (max-width: 480px) {
  /* ANPASSEN DER PADDINGS BEI COL LINE VIEW */
  .line div[class*='-4'] { padding-right: @grid-gutter-width / 2; }
  .line div[class*='-8'] { padding-left: @grid-gutter-width / 2; }
  .line_body { padding: 0; }

  .line_body h2 {
    margin-top: 15px;
  }
}
