//
// Legacy thumbnail overrides
// --------------------------------------------------

.thumbnail {
  &:extend(.tile all);
  &:extend(.c-grid-teaser__tile all);
  position: relative;

  .image {
    &:extend(.tile__img all);
  }

  h2 {
    &:extend(.tile__headline all);

    a,
    a:hover,
    a:focus {
      color: inherit;
    }
  }

  .caption {
    &:extend(.tile__caption all);
  }

  .opentime {
    margin-bottom: 1em;
  }

  .we-intro {
    &:extend(.tile__infotext all);
  }

  .opentime + .we-intro {
    margin-top: .5em;
  }

  .line_dummy_img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
  }
}

.thumbnail_embed_image {
  background: transparent;

  .caption {
    padding: .5rem 0 0;
  }
}
