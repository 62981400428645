//
// Buttons (Bootstrap Override)
// --------------------------------------------------

.btn {
  .font-serif;
  font-weight: @fw-bold;
  border-width: 1px;
  transition: all @anim-short;
}

.btn-ci {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}

.btn-success,
.btn-ci,
.btn-primary,
.btn-default,
.btn-info {
  .font-smoothing;
}
