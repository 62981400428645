/* merriweather-regular - latin */
@font-face {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-regular.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-regular.woff")
            format("woff");
}
/* merriweather-italic - latin */
@font-face {
    font-family: "Merriweather";
    font-style: italic;
    font-weight: 400;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-italic.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-italic.woff")
            format("woff");
}
/* merriweather-700 - latin */
@font-face {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-700.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-700.woff")
            format("woff");
}
/* merriweather-700italic - latin */
@font-face {
    font-family: "Merriweather";
    font-style: italic;
    font-weight: 700;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-700italic.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/merriweather-v30-latin-700italic.woff")
            format("woff");
}

/* open-sans-regular - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-regular.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-regular.woff")
            format("woff");
}
/* open-sans-600 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-600.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-600.woff")
            format("woff");
}
/* open-sans-700 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-700.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-700.woff")
            format("woff");
}
/* open-sans-italic - latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-italic.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-italic.woff")
            format("woff");
}
/* open-sans-600italic - latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-600italic.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-600italic.woff")
            format("woff");
}
/* open-sans-700italic - latin */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 700;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-700italic.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/open-sans-v29-latin-700italic.woff")
            format("woff");
}
/* oswald-700 - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 700;
    src: local(""),
        url("/extension/portal-ngh/design/ngh-de/font/oswald-v49-latin-700.woff2")
            format("woff2"),
        url("/extension/portal-ngh/design/ngh-de/font/oswald-v49-latin-700.woff")
            format("woff");
}
