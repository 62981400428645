// No BEM, since we're in bootstrap land
.c-toggle-container {
  .panel {
    border: none;
    box-shadow: none;
    padding-bottom: 1em;
  }

  .panel + .panel {
    margin-top: 0;
  }

  .panel-heading {
    background-color: transparent;
    border-top: 1px solid @color-content-inverted;
    color: inherit;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .panel-title {
    font-weight: @fw-bold;

    > a {
      display: block;
      position: relative;
      padding: .5em 1em .5em 0;

      &::after {
        font-family: "FontAwesome";
        content: "\f056";
        font-size: 2em;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.collapsed::after {
        content: "\f055";
        color: @color-content-inverted;
      }
    }
  }

  .panel-body {
    border: 0 none !important;
    padding: 0 0 20px;
  }
}

.c-toggle-container__headline {
  margin-bottom: 1em;
}
