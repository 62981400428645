//
// Static map
// --------------------------------------------------

.c-static-map {
  position: relative;
  padding-bottom: 62.5%;
  height: 0;
  overflow: hidden;
}

.c-static-map__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.c-static-map__btn {
  display: block;
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 10; // > map
}
