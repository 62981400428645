//
// Tile
// --------------------------------------------------

@tileImgRatio: (10 / 16) * 100%;
@tileLgImgHeight: @gatewayHeightS;
@tileTransition: @gridTransition;

.tile {
  display: block;
  color: inherit;
  background-color: @color-gray-e;

  aside & { // obviously bad, but liscms2.0 ....
    margin-bottom: 30px;
  }

  // currently only used in grid containers
  .l-grid__item--flex & {
    width: 100%;
  }

  &:hover,
  &:focus {
    color: inherit;
  }
}

.tile--lg {
  @media @desktop {
    display: flex;
    align-items: center;
  }
}

.tile__img {
  display: block;
  background: @color-gray-c;
  min-height: 100px;
  position: relative;
  padding-bottom: @tileImgRatio;
  height: 0;
  overflow: hidden;
  transition: all @tileTransition;

  img {
    vertical-align: middle;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: all @tileTransition;
    transform-origin: center;
  }

  &.is-loading img {
    opacity: 0;
    transform: scale(.95);
  }

  &.has-loaded img {
    opacity: 1;
    transform: scale(1);
  }

  .tile--lg & {
    @media @desktop {
      width: calc(~"(100% -" @grid-gutter-width / 2 ~") * (1 / 3 * 2)");
      min-height: @tileLgImgHeight;
      height: auto;
      padding-bottom: 0;
      flex-shrink: 0;
    }
  }
}

.tile__badge {
  .font-serif();
  font-weight: @fw-semibold;
  background: @color-ci-red;
  color: @color-white;
  .font-smoothing();
  text-transform: uppercase;
  padding: .25em .65em;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.tile__caption {
  padding: 1.25em 1em 1em;

  .tile--lg & {
    @media @desktop {
      width: 100%;
      padding: 1.75em;
      position: relative;
      z-index: 10;

      &::after {
        content: '';
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1.5em 1.5em 1.5em 0;
        border-color: transparent @color-gray-e transparent transparent;
      }
    }
  }
}

.tile__overline {
  .font-serif();
  color: @color-content-inverted;
  margin: 0 0 .25em;

  .tile--lg & {
    text-transform: uppercase;
  }
}

.tile__headline {
  .h3();

  // larger headlines for larger tile
  .tile--lg & {
    .h2();
    padding-bottom: .4em;
    margin-bottom: .4em;
  }

  padding-bottom: .6em;
  margin-bottom: .6em;
  display: block;
  position: relative; // for the separator line

  &::after {
    .separator();
    content: "";
    transform: translateY(50%);
  }

  // could be a link
  &:hover,
  &:focus {
    color: inherit;
  }
}

.tile__infotext {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.tile__infotext + .tile__infotext {
  margin-top: .5em;
}


.tile__link {
  color: @color-content-inverted;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: transform @tileTransition;
  margin-top: 1em;

  .tile--hover:hover & {
    @media @desktop {
      transform: translate(-.5rem, 0);
    }
  }
}

.tile__link-text {
  font-size: @fs-xs;
}

.tile__link-svg {
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-left: .5em;
}

.tile__price {
  text-align: right;
  margin: 0 10px 10px 0;
}
