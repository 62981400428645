//
// Main navigation
// --------------------------------------------------

@navSVGSize: 2.25em;

// staggered nav mixin (called by level1 and level2 links)
.generate-staggered-nav(@n, @selector, @i: 1) when (@i =< @n) {
  @{selector}:nth-child(@{i}) & {
    transition-delay: @i * .04s;
  }
  .generate-staggered-nav(@n, @selector, (@i + 1));
}

.nav__level1,
.nav__level2-list {
  list-style: none;
  margin: 0;
  padding: .75em 1em;

  @media @tablet {
    padding: 1em 2em;
  }
}

.nav__level1 {
  @media @tablet {
    padding: 1em 1em 1em 2em; // since level1 has opener
  }
}

.nav__level1-links {
  display: flex;
  align-items: center;

  // staggered animation
  transform: translateX(-2em);
  opacity: 0;
  transition: all .25s;
  transition-delay: 0s;

  .ngh-overlay--navigation[data-state=open] & {
    transform: translateX(0);
    opacity: 1;
  }

  .generate-staggered-nav(20, ~'.ngh-overlay--navigation[data-state=open] .nav__level1-item');
}

.nav__level1-item,
.nav__level2-item {
  padding-top: .25em;
  padding-bottom: .25em;
}

.nav__level1-link,
.nav__level2-link {
  position: relative;

  font-size: @fs-md;
  line-height: @lh-sm;
  text-transform: uppercase;

  min-height: @navSVGSize;

  display: flex;
  align-items: center;

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  // hover
  &::after {
    content: '';
    background: rgba(0, 0, 0, .25);
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: -.25em; // magic number
    z-index: -1;
    transition: all @anim-short;
  }

  &:hover::after,
  &:focus::after {
    opacity: .36;
    box-shadow: 0 0 0 .2em rgba(0, 0, 0, .25);
  }
}

.nav__level1-link--active,
.nav__level2-link--active {
  font-weight: @fw-bold;
}


.nav__level2-link {
  display: inline-flex;
  align-items: center;

  // staggered animation
  transform: translateX(-2em);
  opacity: 0;
  transition: all .25s;
  transition-delay: 0s;

  .ngh-overlay--navigation .ngh-overlay--sub[data-state=open] & {
    transform: translateX(0);
    opacity: 1;
  }

  .generate-staggered-nav(20, ~'.ngh-overlay--navigation .ngh-overlay--sub[data-state=open] .nav__level2-item');

  // hover adjustments for level: 2;
  &::after {
    left: -.25em; // magic number
  }

  &:hover::after,
  &:focus::after {
    opacity: .5;
  }
}

// semantically belongs to level2, but it's a part of level1 code
.nav__level2-opener {
  padding: 0 1em;
  margin-left: auto;
  min-height: @navSVGSize;
  display: flex;
  align-items: center;
  transition: all @anim-short;

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, .09);
    box-shadow: 0 0 0 .2em rgba(0, 0, 0, .09);
  }

  i {
    font-size: 1.25em;
  }
}

.nav__icon {
  margin-right: .75em;
}

.nav__svg {
  display: block;
  width: @navSVGSize;
  height: @navSVGSize;
}

.nav__parent-link {
  display: flex;
  align-items: center;

  font-size: @fs-md;
  line-height: @lh-sm;
  text-transform: uppercase;

  background-image: linear-gradient(to right, rgba(0, 0, 0, .25), transparent);
  padding: 1em 1em 1.5em;

  @media @tablet {
    padding-left: 2em;
    padding-right: 2em;
  }

  &,
  &:hover,
  &:focus {
    color: inherit;
  }
}
