.c-textbox__topline + .c-textbox__headline {
  margin-top: .125em;
}

.c-textbox__headline {
  hyphens: auto;
  margin-bottom: .4em;
}

.c-textbox__intro {
  .c-richtext;
  .c-richtext--intro;
}

.c-textbox__body {
  .c-richtext;

  .c-textbox__intro + & {
    margin-top: 1em;
  }
}

.c-textbox__body--narrow {
  max-width: 28em;
}
