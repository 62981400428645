//
// Breadcrumb bar
// --------------------------------------------------

.c-breadcrumb-bar__inner {
  background-color: @color-gray-e;
  padding: .25em .5em;

  display: flex;
  align-items: center;
}

.c-breadcrumb-bar__parent-page {
  display: block;
  padding-left: .5em;
  padding-right: .5em;
  text-align: center;
  text-transform: uppercase;
  font-size: @fs-xs;
}

.c-breadcrumb-bar__back,
.c-breadcrumb-bar__actions {
  flex-shrink: 0;
}

.c-breadcrumb-bar__back {
  margin-right: auto;

  display: flex;
  align-items: center;

  span {
    font-size: @fs-xs;
    margin-left: .5em;
  }
}

.c-breadcrumb-bar__actions {
  margin-left: auto;

  display: flex;
  align-items: center;
}

.c-breadcrumb-bar__action {
  & + & {
    margin-left: .5em;
  }
}

.c-breadcrumb-bar__action-link {
  display: block;
}

.c-breadcrumb-bar__svg {
  display: block;
  height: 1.5em;
  width: 1.5em;
}

.c-breadcrumb-bar__dropdown {
  font-size: @font-size-small;
  .dropdown-menu-right();
}

