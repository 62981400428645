.c-styleguide {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 20em;

  * + & {
    margin-top: 3em;
  }
}

.c-styleguide__headline {
  display: inline-block;
  background: @color-black;
  color: @color-white;
  .font-smoothing();
  margin-bottom: 1em;
  padding: .5em;
}

.c-styleguide__section {
  margin-top: 4em;

  &:first-of-type {
    margin-top: 0;
  }
}

.c-styleguide__section-headline {
  display: block;

  font-size: @fs-md;
  line-height: @lh-md;
  font-weight: @fw-regular;
  text-transform: uppercase;
  color: @color-gray-7;

  margin-bottom: 1.5em;
  padding-bottom: .5em;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
}

.c-styleguide__svg {
  background-color: @color-black;
  padding: 2em;
}
