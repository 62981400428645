//
// Flatpickr CSS overrides
// -------------------------

@flatpickr-primary: @color-content-inverted;
@flatpickr-secondary: @color-gray-d;
@flatpickr-disabled-color: @color-gray-c;
@flatpickr-nextmonth-color: @color-gray-9;

.flatpickr-calendar {
  .font-smoothing;
  border-radius: 0;
  box-shadow: 0 14px 36px 2px rgba(0, 0, 0, .15);

  @media (max-width: @screen-phone) {
    left: 11px !important;

    &.rightMost::before,
    &.rightMost::after {
      left: 22px !important;
      right: auto !important;
    }
  }
}

.flatpickr-months .flatpickr-month {
  height: 40px;
}

.flatpickr-current-month input.cur-year {
  font-weight: 700;
}

.flatpickr-weekdays {
  border-bottom: 1px solid @flatpickr-primary;
}

span.flatpickr-weekday {
  color: @flatpickr-primary;
  font-weight: 400;
}

.flatpickr-next-month,
.flatpickr-prev-month {
  height: 45px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #333;
    }
  }
}

.flatpickr-day {
  border-radius: 0;
}

.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  color: @flatpickr-nextmonth-color;
}

.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background-color: @flatpickr-secondary;
  border-color: @flatpickr-secondary;
}

.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover {
  background-color: @flatpickr-primary;
  border-color: @flatpickr-primary;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:focus,
.flatpickr-day.disabled:hover {
  background-color: transparent;
  border-color: transparent;
  color: @flatpickr-disabled-color;
}


// make flatpickr fully responsive
// -------------------------

.flatpickr-calendar,
.flatpickr-weekdays,
.flatpickr-calendar .flatpickr-days {
  max-width: 310px;
}

.flatpickr-innerContainer,
.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-calendar .dayContainer {
  min-width: 0;
  max-width: 100%;
}
