//
// Gallery slider
// --------------------------------------------------

@gallerySliderAspectRatio: 62.5%; // Aspect ratio: 16/10

// Nav
.c-gallery-slider__nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: @gallerySliderAspectRatio;
  height: 0;
}

.c-gallery-slider__nav-item {
  position: absolute;
  z-index: 10; // > swiper-wrapper
  top: 50%;
  transform: translateY(-50%);

  .button-reset;

  opacity: 0;
  transition: opacity @anim-std;

  .swiper-initialized & {
    opacity: 1;
  }
}

.c-gallery-slider__nav-item--prev {
  left: .75em;
}

.c-gallery-slider__nav-item--next {
  right: .75em;
}

.c-gallery-slider__nav-svg {
  display: block;
  height: 2.25em;
  width: 2.25em;

  @media @tablet {
    height: 3.25em;
    width: 3.25em;
  }
}

// Pagination
.swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

// Image
.c-gallery-slider__img-wrapper {
  padding-bottom: @gallerySliderAspectRatio;
  height: 0;
  position: relative;
}

.c-gallery-slider__img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  object-fit: cover;
}

// Caption
.c-gallery-slider__caption {
  font-size: @fs-xs;
  margin-top: .5em;
  color: @color-content-active;
}
