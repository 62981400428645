// Generic page item gutter spacing
@pageItemGutterXS: 3em;
@pageItemGutterSM: 4em;
@pageItemGutterMD: 5em;

.c-page__item {
  margin-top: @pageItemGutterXS;
  margin-bottom: @pageItemGutterXS;

  @media @tablet {
    margin-top: @pageItemGutterSM;
    margin-bottom: @pageItemGutterSM;
  }

  @media @desktop {
    margin-top: @pageItemGutterMD;
    margin-bottom: @pageItemGutterMD;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// Exception: Adjacent Big Teasers
.c-page__item--teaser_big {
  @pageItemGutterTeaserBig: 1em;

  & + & {
    margin-top: -@pageItemGutterXS + @pageItemGutterTeaserBig;

    @media @tablet {
      margin-top: -@pageItemGutterSM + @pageItemGutterTeaserBig;
    }

    @media @desktop {
      margin-top: -@pageItemGutterMD + @pageItemGutterTeaserBig;
    }
  }
}

// Exception 2: Waymarkers
.c-page__item--waymarker {
  margin-top: 7em;

  @media @tablet {
    margin-top: 8em;
  }

  @media @desktop {
    margin-top: 8em;
  }
}
