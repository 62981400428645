//
// Waymarker
// --------------------------------------------------

@waymarker-marker-height: 5em;
@waymarker-continue-height: 5em;
@waymarker-initial-height: 5em;
@waymarker-track-anim-duration: .75s;

.c-waymarker {
  height: @waymarker-initial-height; // set height to give the pulsing icon some space and keep margins from collapsing

  &.is-active {
    height: auto;
  }
}

.c-waymarker__toggleable {
  position: relative;
  z-index: @zindex-navbar - 50; // higher than storytelling track, lower than bootstraps absolute components

  height: 0;
  overflow: hidden;
  transform: translateY(2rem);
  transition: all @anim-std;

  .c-waymarker.is-active & {
    height: auto;
    transform: none;
  }
}

.c-waymarker__inner {
  padding-top: 4em;
  padding-bottom: 3em;
  background-color: @color-gray-c;
  background-image: url('/extension/portal-ngh/design/ngh-de/images/bg-waymarker.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.c-waymarker__track-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2.5em;
  right: @grid-gutter-width;
  z-index: 10;

  transition: all @anim-std;

  @media @tablet {
    left: calc(~"((100% -" @containerWidthSlim ~") / 2) + 3.25em");
    right: calc(~"(100% -" @containerWidthSlim ~") / 2");
  }

  @media @desktop {
    left: calc(~"((100% -" @containerWidthSlim + @grid-gutter-width * 2 ~") / 2) - 3.25em");
  }

  @media @desktopLarge {
    left: calc(~"((100% -" @containerWidthSlim + @grid-gutter-width * 2 ~") / 2) - 6em");
  }
}

.c-waymarker__track {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: calc(100% ~"-" @waymarker-marker-height / 2 ~"-" @waymarker-continue-height);
  width: @storytellingTrackWidth;
  background-color: @color-content-inverted;
  border-bottom-left-radius: @storytellingTrackBorderRadius;
  border-bottom-right-radius: @storytellingTrackBorderRadius;

  transform: scaleY(0);
  transform-origin: center top;

  .c-waymarker.is-active & {
    transition: all @waymarker-track-anim-duration;
    transform: scaleY(1);
  }
}

.c-waymarker__marker {
  position: absolute;
  left: 0;
  right: 0;
  bottom: @waymarker-continue-height;
  height: @waymarker-marker-height;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  span,
  svg {
    opacity: 0;
    transform: translateX(-1rem);

    .c-waymarker.is-active & {
      transition: all @waymarker-track-anim-duration @waymarker-track-anim-duration; // chaining with track by delay
      transform: none;
      opacity: 1;
    }
  }

  span {
    @media @tablet {
      white-space: nowrap;
    }
  }

  svg {
    flex-shrink: 0;
    display: block;
    width: @storytellingIconSize;
    height: @storytellingIconSize;
    margin-left: 1rem;

    @media @tablet {
      font-size: 1.38rem;
    }
  }

  // horizontal track
  &::before {
    content: "";
    display: block;
    background: @color-content-inverted;
    height: @storytellingTrackWidth;
    border-radius: @storytellingTrackBorderRadius;
    width: 100%;
    margin-right: 1em;

    transform: scaleX(0);
    transform-origin: left center;

    .c-waymarker.is-active & {
      transition: all @waymarker-track-anim-duration @waymarker-track-anim-duration; // chaining with track by delay
      transform: scaleX(1);
    }
  }
}

.c-waymarker__continue {
  position: absolute;
  bottom: 0;
  left: @storytellingTrackWidth / 2;
  transform: translateX(-50%);
  height: @waymarker-continue-height;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    text-align: center;
    max-width: 4em;
    font-size: @fs-xs;
    line-height: @lh-md;

    @media @tablet {
      max-width: none;
      font-size: @fs-sm;
    }
  }

  svg {
    display: block;
    width: 2em;
    height: 2em;
  }
}

.c-waymarker__relation {
  padding-bottom: 4em;
  margin-bottom: @waymarker-continue-height;
  position: relative;
  z-index: 10; // > track-wrapper

  .line_body .btn {
    display: none;
  }
}
