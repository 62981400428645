.c-topline {
  .font-serif;
  font-weight: @fw-regular;
  text-transform: uppercase;
  font-size: @fs-2md;
  line-height: @lh-md;
  color: @color-content-inverted;

  @media @tablet {
    font-size: @fs-lg;
  }
}

.c-topline--sm {
  @media @tablet {
    font-size: @fs-2md;
  }
}

.c-topline--md {
  @media @tablet {
    font-size: @fs-3md;
  }
}
