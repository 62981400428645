//
// Legacy LIS CMS styles
// --------------------------------------------------

@liscms-color-red: @color-ci-red;
@liscms-color-green: @color-ci-green;

/** DEBUG **/
#debug {
  color: #444;
  background: #eee;
  padding: 30px;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  pre {
    white-space: normal;
  }
}
#bwidth{ font-weight: 400; display: block; text-align: center; background: #000; position: absolute; bottom: 0; right: 0; z-index: 100000; color: #fff; padding: 5px; }

#pagelayout_login {
    background: url("/design/admin/images/3/dark_back.png") repeat scroll 0 0 transparent;
    margin-top: 30px;
    min-height: 100%;
}


/* BADGES */
.badge.green { background: @liscms-color-green; color: #fff; }
.badge.red { background: @liscms-color-red; color: #fff; }

/* BOOTSTRAP OVERRIDE */
.panel-group { margin-bottom: 5px; }
.panel-group .panel { border-radius: 0; }
.accordion-toggle { display: block; }
.accordion-toggle.collapsed:after { font-family: 'FontAwesome'; content: "\f055"; float: right; color: #444; }
.accordion-toggle:after { font-family: 'FontAwesome'; content: "\f056"; float: right; color: #444; }

/* HELPER */
.m0 { margin: 0; }
.mt0 { margin-top: 0; }
.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.br0 { border-radius: 0; }
.white { color: #fff; }
.red { color: @liscms-color-red; }
.bold { font-weight: 700; }

/* MISC */
#map { height: 600px; }
.text-success a span,
.text-success a i { color: @liscms-color-green; }
.text-error a span,
.text-error span,
.text-error i,
.text-error a i { color: @liscms-color-red; }
form { position: relative; }
.filter_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    background: #fff;
    margin-left: -58px;
    margin-top: -58px;
    z-index: 100;
    border: solid 1px #c2c2c2;
}
#filter_loader_map {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    background: #fff;
    margin-left: -58px;
    margin-top: -58px;
    z-index: 100;
    border: solid 1px #c2c2c2;
}

.webcam_enlarge { position: absolute; bottom: 15px; right: 15px; z-index: 100; color: white; }
.search_result_offcanvas_right {
    min-height: 450px;
    position: relative;
}

/* Maxwidth FIX*/
#map * img,
.map * img,
.full_children_map img {
    max-width:none;
}

/* SEARCH */
.facet_box .thumbnail .caption {
  padding: 10px;
}

.full_ezfind_search .panel-heading { padding: 9px; }
.full_ezfind_search .accordion-toggle { color: #444; font-weight: 700; }
.facet-item { margin-bottom: 3px; }
.facet-item label.checkbox { font-weight: normal; margin: 0; }
.btn.selectedfacet { text-align: left; padding-left: 10px; }
.facet_box_offcanvas_close_icon, #facet_box_offcanvas_trigger_mobile { display: none; }
p.searchresult { color: #333; margin: 0; }

/* NEW FACETS FOR TOURS - POI */
.full_archive .panel-heading {
    padding: 9px;
}

.facet-item .checkbox {
    margin: 0;
}

.facet-item .facet-label-left {
    float: left;
    width: calc(~'100% - 50px');
}

.facet-item .facet-count-right {
    float: right;
    text-align: right;
    width: 50px;
}

.facets-more-open,
.facets-more-close {
    display: inline-block;
    margin-top: 10px;
}

.facets-more-open i {
    position: relative;
    top: -1px;
}

.facets-more-close i {
    position: relative;
    top: 5px;
}

/* THUMBNAIL */
.thumbnail_content {
    line-height: @line-height-base;
    transition: all .2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 30px;
    position: relative;
    word-wrap: break-word;

    //.cmp-bg();

    h3 {
      margin-bottom: 0.5em;
    }
}
.thumbnail_content .image img,
.thumbnail .image img {
  max-width: 100%;
}
.thumbnail_content .caption { padding: 5px 9px 9px; }
.thumbnail_content .panel-group:last-child { margin-bottom: 0; }
.thumbnail_content > img { display: block; max-width: 100%; height: auto; }
.thumbnail.thumbnail_tile_actioncole h2,
.thumbnail.thumbnail_tile h2 { margin-bottom: 5px; }
.thumbnail_dummy_img { background: #2C3033; text-align: center; height: 230px; width: 100%; position: relative; }
.thumbnail.thumbnail_tile .thumbnail_dummy_img { height: 230px; }
.thumbnail_dummy_img i { color: #fff; font-size: 100px; position: absolute; top: 50%; left: 50%; margin-left: -53px; margin-top: -51px; }
.thumbnail_embed_image .caption { padding: 9px; }
.thumbnail.thumbnail_tile { height: 355px; }
.thumbnail.thumbnail_tile.readmore { height: 375px; }
.thumbnail.thumbnail_tile .image { height: 230px; overflow: hidden; }
.thumbnail.thumbnail_tile_actioncole { height: 320px; }
.thumbnail.thumbnail_tile_actioncole.readmore { height: 360px; }
.thumbnail.thumbnail_tile_actioncole .image,
.thumbnail.thumbnail_tile_actioncole .thumbnail_dummy_img { height: 170px; overflow: hidden; }
.thumbnail_gallery_thumb { max-width: 100px; margin-right: 10px;}

.thumbnail_readmore { position: absolute; left: 9px; bottom: 9px; z-index: 10; }
.thumbnail_staticmap { background-position: 50% 50%; max-height: 350px; background-repeat: no-repeat; background-color: #2C3033; margin-bottom: 0; overflow: hidden; }
.thumbnail_aside .thumbnail_staticmap { border: none; margin-bottom: 10px; }
.thumbnail_staticmap img { opacity: 0; }
.thumbnail_aside { word-wrap: break-word; }
.thumbnail_aside li a { line-height: 28px; }
.thumbnail_clicked { cursor: pointer; }
.thumbnail_webcam .image { position: relative; }
.thumbnail_person img { margin: -35px auto 0 auto; }
.thumbnail_person .image { overflow: hidden; }
.thumbnail_person_position { margin-bottom: 5px; }

/* TODO: noch nicht fertig */
.fullview_template .thumbnail.thumbnail_tile_actioncole .image,
.fullview_template .thumbnail.thumbnail_tile .image,
.fullview_template .thumbnail.thumbnail_tile .thumbnail_dummy_img,
.fullview_template .thumbnail.thumbnail_tile_actioncole .thumbnail_dummy_img { height: 135px; overflow: hidden; }
.fullview_template .thumbnail.thumbnail_tile,
.fullview_template .thumbnail.thumbnail_tile_actioncole { height: 300px; }
/* TODO: noch nicht fertig */

/* FULL */
.full_reply { min-height: 350px; background: #fff; padding: 10px; }

/* MAGNIFIC POPUP OVERRIDES */
.mfp-content { font-size: 12px; }
.mfp-counter { top: 8px; width: 100%; }
.mfp-title { padding-right: 0; }

/* IAS LOADER PURE CSS */
.css-loader {
    position: absolute;
    top: 280px;
    left: 50%;
    margin-left: -72px;
    z-index: 1000;
}

@keyframes spin {
	to { transform: rotate(1turn); }
}

.lisprogress {
	position: relative;
	display: inline-block;
	width: 5em;
	height: 5em;
	margin: 0 .5em;
	font-size: 24px;
	text-indent: 999em;
	overflow: hidden;
	animation: spin 1s infinite steps(8);
	-webkit-animation: spin 1s infinite steps(8);
    -moz-animation: spin 1s infinite steps(8);
    -o-animation: spin 1s infinite steps(8);
}

.lisprogress:before,
.lisprogress:after,
.lisprogress > div:before,
.lisprogress > div:after {
	content: '';
	position: absolute;
	top: 0;
	left: 2.25em; /* (container width - part width)/2  */
	width: .5em;
	height: 1.5em;
	border-radius: .2em;
	background: #eee;
	box-shadow: 0 3.5em #eee; /* container height - part height */
	transform-origin: 50% 2.5em; /* container height / 2 */
}

.lisprogress:before {
	background: #555;
}

.lisprogress:after {
	transform: rotate(-45deg);
	background: #777;
}

.lisprogress > div:before {
	transform: rotate(-90deg);
	background: #999;
}

.lisprogress > div:after {
	transform: rotate(-135deg);
	background: #bbb;
}

/* PAGINATION */
.child_pagination { display: none }

/*VIDEO*/
video {
  max-width: 100%;
  height: auto;
}
.video-container {
  position: relative;
  padding-bottom: 66.6666666%;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
MEDIA QUERIES
========================================================================== */

@media (min-width: 992px) and (max-width: 1199px) {

    /* ANPASSEN DER BILDGROESSE UND TUMBNAILGROESSE IN DER KACHELANSICHT */
    .thumbnail.thumbnail_tile .image,
    .thumbnail.thumbnail_tile .thumbnail_dummy_img { height: 185px; }
    .thumbnail.thumbnail_tile_actioncole { height: 300px; }
    .thumbnail.thumbnail_tile_actioncole .image,
    .thumbnail.thumbnail_tile_actioncole .thumbnail_dummy_img { height: 135px; overflow: hidden; }
    .thumbnail.thumbnail_tile_actioncole.readmore { height: 340px; }

}

@media (min-width: 768px) and (max-width: 991px) {

    /* ANPASSEN DER BILDGROESSE UND TUMBNAILGROESSE IN DER KACHELANSICHT */
    .thumbnail.thumbnail_tile { height: 305px; }
    .thumbnail.thumbnail_tile.readmore { height: 345px; }
    .thumbnail.thumbnail_tile .image,
    .thumbnail.thumbnail_tile .thumbnail_dummy_img { height: 140px; }

}

@media (max-width: 767px) {

    /* ANPASSEN DER BILDGROESSE UND TUMBNAILGROESSE IN DER KACHELANSICHT */
    .thumbnail.thumbnail_tile { height: 315px; }
    .thumbnail.thumbnail_tile.readmore { height: 355px; }
    .thumbnail.thumbnail_tile .image,
    .thumbnail.thumbnail_tile .thumbnail_dummy_img { height: 175px; }
    .fullview_template .thumbnail.thumbnail_tile_actioncole .image,
    .fullview_template .thumbnail.thumbnail_tile .image,
    .fullview_template .thumbnail.thumbnail_tile .thumbnail_dummy_img,
    .fullview_template .thumbnail.thumbnail_tile_actioncole .thumbnail_dummy_img { height: 215px; overflow: hidden; }
    .fullview_template .thumbnail.thumbnail_tile,
    .fullview_template .thumbnail.thumbnail_tile_actioncole { height: 320px; }

    .thumbnail_filter select { margin-bottom: 15px; }

    /* OFFCANVAS FACETS EZFIND */
    .facet_box .thumbnail .caption {
      background: transparent;
    }

    .facet_box_open .thumbnail .caption {
      background: white;
    }

    .facet_box_open .facet_box_offcanvas_close_icon {
        cursor: pointer;
        display: block;
        font-size: 30px;
        position: absolute;
        text-shadow: 0px 0px 3px #fff;
        top: -16px;
        right: -16px;
    }
    #facet_search, #form-search, .facet_box .quickfinder-facets, .facet_box_content { display: none }
    .facet_box_open #facet_search { display: block }
	.facet_box_open #facet_search,
	.facet_box_open #form-search,
	.facet_box_open .facet_box_content { display: block }
	.facet_box_open .toggle-next + #facet_search,
	.facet_box_open .toggle-next + #form-search,
	.facet_box_open .toggle-next + .facet_box_content { display: none }
    .facet_box_offcanvas_trigger_desktop { cursor: pointer; margin-bottom: 15px; }
    .facet_box_offcanvas_trigger_desktop .badge { position: absolute; right: 3px; top: 3px; z-index: 100; }
    .facet_box {
        min-height: 2000px;
        position: absolute;
        left: 0;
        top: auto;
        z-index: 100;
        padding: 0;
        width: 300px;
        -webkit-transform: translateX(-250px);
        -moz-transform: translateX(-250px);
        transform: translateX(-250px);
        -webkit-transition: -webkit-transform 0.3s, width 0.3s;
        -moz-transition: -moz-transform 0.3s, width 0.3s;
        transition: transform 0.3s, width 0.3s;
    }
    .facet_box.facet_box_open {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-transition: -webkit-transform 0.3s, width 0.3s;
        -moz-transition: -moz-transform 0.3s, width 0.3s;
        transition: transform 0.3s, width 0.3s;
    }
    .col-sm-8.search_result_offcanvas_right {
        float: right;
        width: calc(100% - 8px);
        width: -moz-calc(100% - 8px);
        width: -webkit-calc(100% - 8px);
    }
    .facet_box .thumbnail { min-height: 2000px; }

}

@media (max-width: 600px) {

    /* ANPASSEN DER BILDGROESSE UND TUMBNAILGROESSE IN DER KACHELANSICHT */
    .thumbnail.thumbnail_tile { height: 295px; }
    .thumbnail.thumbnail_tile.readmore { height: 335px; }
    .thumbnail.thumbnail_tile .image,
    .thumbnail.thumbnail_tile .thumbnail_dummy_img { height: 135px; }
    .thumbnail.thumbnail_tile_actioncole { height: 300px; }
    .thumbnail.thumbnail_tile_actioncole.readmore { height: 340px; }
    .thumbnail.thumbnail_tile_actioncole .image,
    .thumbnail.thumbnail_tile_actioncole .thumbnail_dummy_img { height: 135px; overflow: hidden; }

    /* OFFCANVAS FACETS EZFIND */
    .col-sm-8.search_result_offcanvas_right {
        float: right;
        width: calc(100% - 10px);
        width: -moz-calc(100% - 10px);
        width: -webkit-calc(100% - 10px);
    }

}

@media (max-width: 599px) {

    .full_archive #facet_box_offcanvas_trigger_mobile,
    .full_ezfind_search #facet_box_offcanvas_trigger_mobile {
        position: inherit;
        margin: 0;
        left: auto;
    }

}

@media (max-width: 480px) {
    /* CUSTOM LIS TINY COL HACK - FULL WIDTH OF COLS AT >= 480px */
    #full_children_tiles div[class*='col-'],
    #full_children_isotope div[class*='col-'],
    #full_children_line div[class*='col-'],
    #full_children_search div[class*='col-'] { float: none; width: 100%; }

    /* ANPASSEN DER BILDGROESSE UND TUMBNAILGROESSE IN DER KACHELANSICHT */
    .thumbnail.thumbnail_tile,
    .thumbnail.thumbnail_tile_actioncole,
    .thumbnail.thumbnail_tile.readmore,
    .thumbnail.thumbnail_tile_actioncole.readmore,
    .thumbnail.thumbnail_tile_actioncole .image,
    .thumbnail.thumbnail_tile .image { height: auto; }
    .thumbnail_readmore { position: inherit; }
    .thumbnail.thumbnail_tile .thumbnail_dummy_img,
    .thumbnail.thumbnail_tile_actioncole .thumbnail_dummy_img { height: 290px; }

    /* OFFCANVAS FACETS EZFIND */
    .facet_box_open .facet_box_offcanvas_close_icon {
        right: 50%;
        right: -webkit-calc(50% - 8px);
        right: -moz-calc(50% - 8px);
        right: calc(50% - 8px);
        top: -20px;
    }
    .facet_box {
        width: 100%;
        position: absolute;
        left: -100%;
        top: auto;
        z-index: 100;
        padding: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: -webkit-transform 0.3s, width 0.3s;
        -moz-transition: -moz-transform 0.3s, width 0.3s;
        transition: transform 0.3s, width 0.3s;
    }
    .facet_box.facet_box_open {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.3s, width 0.3s;
        -moz-transition: -moz-transform 0.3s, width 0.3s;
        transition: transform 0.3s, width 0.3s;
    }
    .facet_box .thumbnail { min-height: 2000px; }
    .col-sm-8.search_result_offcanvas_right { width: 100% }
    #facet_box_offcanvas_trigger_mobile { display: block; }
    p.searchresult { margin: 15px 0 0 0; }

}
