// Main content
// -------------------------


.main__content {
  padding-top: @pageItemGutterXS;
  padding-bottom: @pageItemGutterXS;

  @media @tablet {
    padding-top: @pageItemGutterSM;
    padding-bottom: @pageItemGutterSM;
  }

  @media @desktop {
    padding-top: @pageItemGutterMD;
    padding-bottom: @pageItemGutterMD;
  }

  // special spacing for tportal
  .main--tportal & {
    padding-top: 1em;

    @media @tablet {
      padding-top: 2em;
    }
  }
}

.main__content--no-pad-top {
  padding-top: 0;
}
