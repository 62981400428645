//
// Container Layout module (kicks bootstrap's overly complicated ass)
// -------------------------

@containerWidth: @container-lg;
@containerWidthSlim: 740px;
@containerWidthSlimX: 600px;

.l-container {
  padding-left: @grid-gutter-width;
  padding-right: @grid-gutter-width;

  @media @tablet {
    max-width: @containerWidth + @grid-gutter-width * 2;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-container--slim {
  @media @tablet {
    max-width: @containerWidthSlim + @grid-gutter-width * 2;
  }
}

.l-container--slim-x {
  @media @tablet {
    max-width: @containerWidthSlimX + @grid-gutter-width * 2;
  }
}

// full width container
.l-container--full {
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;

  @media @tablet {
    max-width: none;
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;
  }
}

// only applies to desktop upwards
.l-container--desktop {
  padding-left: 0;
  padding-right: 0;

  @media @desktop {
    padding-left: @grid-gutter-width / 2;
    padding-right: @grid-gutter-width / 2;
  }
}
