//
// LIS Map
// --------------------------------------------------

.container ~ #lismap {
  margin-top: 2em;
}

.lismap__nav-trigger-icon {
  background: @color-content-inverted !important;
}

.lismap__nav-trigger {
  background: @color-content-inverted !important;
  .font-smoothing;
}

.lismap__nav-list > li.active > a {
  background: @color-gray-3 !important;
  .font-smoothing;
}

.lismap,
.lismap__map {
  height: 400px !important;

  @media @tablet {
    height: 500px !important;
  }

  @media @desktopLarge {
    height: 650px !important;
  }
}

// Fullscreen z-index
.leaflet-fullscreen-on.leaflet-pseudo-fullscreen {
  z-index: @zindex-navbar-fixed + 5 !important; // topbar
}

.lismap__message__action-button {
  background-color: @color-content-inverted !important;
  .font-smoothing;
}

// Toursprung map
.c-static-map {
  .tmap {
    position: absolute !important;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: none;
  }
}
