//
// Fullview
// --------------------------------------------------

// Full Intro / Full Body

.full_head,
.full__head {
  // headline styles
  h1 {
    &:extend(.h2);
    margin-bottom: .25em;
  }
}

.full_intro {
  .c-richtext;
  .c-richtext--intro;
}

.full_head + .full_body, // no action col
.full_body ~ .full_body, // multiple full_bodies (e.g. imprint)
.full_content { // action col, class should be set on row
  margin-top: 2.5em;
}

// Full Children (only used in press extension)

.full_children {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid @color-gray-e;

  @media @tablet {
    margin-top: 3em;
    padding-top: 3em;
  }
}

// Action Col
// .full_article {
//   aside {
//     padding-top: 30px;

//     @media @desktop {
//       padding-top: 0;
//       padding-left: @grid-gutter-width;
//     }
//   }
// }
